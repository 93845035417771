import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import validator from 'validator'
import EmailInput from '../components/controls/EmailInput'
import axios from 'axios'

const checkIcon = <FontAwesomeIcon icon={faCheckCircle} color="green" />

const Support = () => {
  useEffect(() => {
    document.title = 'Support'
  }, [])

  const [name, setName] = useState('')
  const [isNameTouched, setIsNameTouched] = useState(false)
  const [isNameValid, setIsNameValid] = useState(false)

  const [email, setEmail] = useState('')
  const [isEmailTouched, setIsEmailTouched] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)

  const [supportText, setSupportText] = useState('')
  const [isSupportTextTouched, setIsSupportTextTouched] = useState(false)
  const [isSupportTextValid, setIsSupportTextValid] = useState(false)

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false)

  const [apiErrors, setApiErrors] = useState('')

  const apiEndpoint = process.env.REACT_APP_MEMREEL_API_ENDPOINT

  const handleNameChange = (event) => {
    setName(event.target.value)
    const isEmpty = validator.isEmpty(event.target.value)
    setIsNameTouched(!isEmpty)
    setIsNameValid(!isEmpty)
  }

  const validateName = (event) => {
    setIsNameTouched(true)
    setIsNameValid(!validator.isEmpty(event.target.value))
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
    setIsEmailTouched(!validator.isEmpty(event.target.value))
    setIsEmailValid(validator.isEmail(event.target.value))
  }

  const handleSupportTextChange = (event) => {
    setSupportText(event.target.value)
    setIsSupportTextTouched(!validator.isEmpty(event.target.value))
    setIsSupportTextValid(!validator.isEmpty(event.target.value))
  }

  const validateSupportText = (event) => {
    setIsSupportTextTouched(true)
    setIsSupportTextValid(!validator.isEmpty(event.target.value))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!isNameValid || !isEmailValid || !isSupportTextValid) {
      print('here')
      setIsNameTouched(true)
      setIsEmailTouched(true)
      setIsSupportTextTouched(true)
      setIsSuccessfullySubmitted(false)
      return
    }

    try {
      await axios.post(`${apiEndpoint}/support`, {
        email,
        from: name,
        body: supportText
      })
      setIsSuccessfullySubmitted(true)
      setApiErrors('')
    } catch (error) {
      setIsSuccessfullySubmitted(false)
      if (error.response.data) {
        setApiErrors(error.response.data.errors)
      }
    }
  }

  const supportForm = () => {
    return (
      <form className="box" onSubmit={ handleSubmit }>
        <div className="container has-text-centered mb-5">
          <h1 className="title is-4">MemReel Support</h1>
        </div>
        <div className="field">
          <label className="label">Name</label>
          <div className="control has-icons-right">
            <input className="input"
              type="text"
              placeholder="Name"
              value={ name }
              onChange={ handleNameChange }
              onBlur={ validateName }
            />
            <span className="icon is-right">
              { isNameValid && checkIcon }
            </span>
            { isNameTouched && !isNameValid && <p className="help is-danger">Please enter a valid name</p> }
            <span className="icon is-right">
              { isNameValid && checkIcon }
            </span>
          </div>
        </div>
        <EmailInput
          email={ email }
          isEmailValid={ isEmailValid }
          onChange={ handleEmailChange }
          touched={ isEmailTouched }
        />
        <div className="field">
          <label className="label">Support</label>
          <div className="control has-icons-right">
            <textarea className="textarea"
              type="text"
              placeholder="How can we help you?"
              value={ supportText }
              onChange={ handleSupportTextChange }
              onBlur={ validateSupportText }
            />
            <span className="icon is-right">
              { isSupportTextValid && checkIcon }
            </span>
            { isSupportTextTouched && !isSupportTextValid && <p className="help is-danger">Please enter your support question or concern</p> }
            <span className="icon is-right">
              { isSupportTextValid && checkIcon }
            </span>
          </div>
        </div>
        <button className="button is-primary is-fullwidth">Send Request</button>
      </form>
    )
  }

  return (
    <div className="container support-form">
      <section className="hero is-fullheight-with-navbar">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-5-tablet is-5-desktop is-5-widescreen">
                { apiErrors &&
                  <article className="message is-danger is-light">
                    <div className="message-body">Error: { apiErrors }</div>
                  </article> }
                { isSuccessfullySubmitted
                  ? <article className="message is-success is-light">
                      <div className="message-body">Your support request has been submitted</div>
                    </article>
                  : supportForm()
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Support
