import React from 'react'

function Landing () {
  return (
    <div className="container">
      <div className="columns is-centered is-vcentered">
        <div className="column is-5-tablet is-5-desktop is-5-widescreen">
          <div className="container has-text-centered">
            <img src="/logo-horizontal.svg" alt="memreel-logo" />
            <p className="subtitle logo-subtitle is-4">Capture Your Life Journey In A Reel</p>
            <h2 className="subtitle memreel-oneliner">
              Tell your story with Memreel. Capture and relive your most precious moments with your loved ones privately.
            </h2>
          </div>
          <img className="mt-5 is-hidden-desktop" src="/mobile-landing-image.png" alt="memreel-overview" />
          <div className="mt-5 container store-links has-text-centered">
            <a className="p-2" href="https://apps.apple.com/dk/app/memreel/id6447718982" target="_blank" rel="noreferrer">
              <img src="/apple-app-store-badge.png" alt="app-store-badge" />
            </a>
            <a className="p-2" href="https://play.google.com/store/apps/details?id=io.memreel.memreel" target="_blank" rel="noreferrer">
              <img src="/google-play-store-badge.png" alt="google-play-badge" />
            </a>
          </div>
        </div>
        <div className="column is-5-tablet is-5-desktop is-5-widescreen is-hidden-mobile is-hidden-touch">
          <img src="/landing-right-image.png" alt="memreel-overview" />
        </div>
      </div>
    </div>
  )
}

export default Landing
