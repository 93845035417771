import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <>
      <div className="container has-text-centered">
        <div className="columns is-centered">
          <div className="column is-4-tablet is-4-desktop is-4-widescreen">
            <p className="subtitle is-1">404</p>
            <h2>Sorry, that page cannot be found</h2>
            <p>Go back to the <Link to="/">Homepage</Link></p>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
