import React from 'react'
import { Outlet, Link } from 'react-router-dom'
// import AccountMenu from '../components/navbar/AccountMenu'
// import { useAuthState } from '../components/shared/AuthContext'

const Layout = () => {
  // const { account } = useAuthState()

  // const [isBurgerActive, setIsBurgetActive] = React.useState(false)

  // function onBurgerMenuClick () {
  //   setIsBurgetActive(!isBurgerActive)
  //   // Set the navbar menu to active or inactive
  //   const navbarMenu = document.getElementById('memreelNavbar')
  //   if (navbarMenu) {
  //     if (isBurgerActive) {
  //       navbarMenu.classList.remove('is-active')
  //     } else {
  //       navbarMenu.classList.add('is-active')
  //     }
  //   }
  // }

  // function toggleBurgerIcon () {
  //   setIsBurgetActive(false)
  // }

  return (
    <>
      {/* <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" to={account ? '/home' : '/'}>
            <img src="/logo512.png" alt="memreel-logo" />
          </Link>
          <a role="button"
            className={`navbar-burger ${isBurgerActive ? 'is-active' : ''}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="memreelNavbar"
            onClick={onBurgerMenuClick}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="memreelNavbar" className={`navbar-menu ${isBurgerActive ? 'is-active' : ''}`}>
          <div className="navbar-start">
            {account && (
              <NavLink className="navbar-item" to="/friends" onClick={toggleBurgerIcon}>Friends</NavLink>
            )}
          </div>
          {account && (
            <AccountMenu
              toggleBurgerIcon={ toggleBurgerIcon }
            />
          )}
        </div>
      </nav> */}
      {/* <div className="main-container">
        <div className="main-content"> */}
          <section className="hero is-fullheight">
            <div className="hero-body">
              <div className="container">
                <Outlet />
                <footer className="footer column is-narrow has-text-centered">
                  <Link className="content is-small footer-link"
                    to='/terms-of-service'
                    style={{ marginRight: 10 }}>
                      Terms of Service
                  </Link>
                  <Link className="content is-small footer-link"
                    to='/privacy-policy'
                    style={{ marginRight: 10 }}>
                      Privacy Policy
                  </Link>
                  <Link className="content is-small footer-link"
                    to='/disclaimer'
                    style={{ marginRight: 10 }}>
                      Disclaimer
                  </Link>
                  <Link className="content is-small footer-link"
                    to='/cookies'
                    style={{ marginRight: 10 }}>
                      Cookie Policy
                  </Link>
                  <Link className="content is-small footer-link"
                    to='/support'
                    style={{ marginRight: 10 }}>
                      Support
                  </Link>
                  <a className="content is-small footer-link"
                    href="#"
                    onClick={() => {
                      window.displayPreferenceModal()
                      return false
                    }}
                    id="termly-consent-preferences">
                      Consent Preferences
                  </a>
                </footer>
              </div>
            </div>
          </section>
        {/* </div>
      </div> */}
    </>
  )
}

export default Layout
