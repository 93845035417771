import React from 'react'
import { Link } from 'react-router-dom'

const Disclaimer = () => {
  React.useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://app.termly.io/embed-policy.min.js'
    script.async = true
    document.body.appendChild(script)
  }, [])

  return (
    <>
      <div className="container terms-of-service-page">
        <div className="columns is-centered">
          <div className="column is-4-tablet is-4-desktop is-4-widescreen">
            <div className="container has-text-centered">
              <Link to='/'>
                <img src="/logo-horizontal.svg" alt="memreel-logo" />
              </Link>
            </div>
          </div>
        </div>
        <div name="termly-embed" data-id="624880b0-391a-4909-828e-8d646a1bc91c" data-type="iframe"></div>
      </div>
    </>
  )
}

export default Disclaimer
