import React from 'react'
import { Link } from 'react-router-dom'

const TermsOfService = () => {
  React.useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://app.termly.io/embed-policy.min.js'
    script.async = true
    document.body.appendChild(script)
  }, [])

  return (
    <>
      <div className="container terms-of-service-page">
        <div className="columns is-centered">
          <div className="column is-4-tablet is-4-desktop is-4-widescreen">
            <div className="container has-text-centered">
              <Link to='/'>
                {/* <figure className="image is-inline-flex"> */}
                  <img src="/logo-horizontal.svg" alt="memreel-logo" />
                {/* </figure> */}
              </Link>
            </div>
          </div>
        </div>
        <div name="termly-embed" data-id="6c2fcc2f-e0f9-48d8-bba5-5b0689d12f3a" data-type="iframe"></div>
      </div>
    </>
  )
}

export default TermsOfService
