import React from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import axios from 'axios'

const ACCESS_TOKEN_LS_KEY = 'mat'

export const STATUS_IDLE = 'idle'
export const STATUS_PENDING = 'pending'
export const STATUS_RESOLVED = 'resolved'
export const STATUS_REJECTED = 'rejected'

const apiEndpoint = process.env.REACT_APP_MEMREEL_API_ENDPOINT
const axiosInstance = axios.create({
  withCredentials: true
})

const AuthStateContext = React.createContext()
const AuthDispatchContext = React.createContext()

function reducer (currentState, newState) {
  return { ...currentState, ...newState }
}

function useAuthState () {
  const context = React.useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider')
  }

  return context
}

function useAuthDispatch () {
  const context = React.useContext(AuthDispatchContext)
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider')
  }

  return context
}

function getStateFromLocalStorage () {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_LS_KEY)
  if (accessToken) {
    return {
      account: jwtDecode(accessToken),
      status: STATUS_RESOLVED,
      errors: null
    }
  }

  return inialState
}

const inialState = {
  account: null,
  status: STATUS_IDLE,
  errors: null
}

function AuthProvider (props) {
  const [state, setState] = React.useReducer(reducer, getStateFromLocalStorage())

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={setState}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

async function reset (dispatch) {
  localStorage.removeItem(ACCESS_TOKEN_LS_KEY)
  dispatch(inialState)
  return <Navigate to='/signin'></Navigate>
}

async function authenticate (dispatch, payload) {
  try {
    dispatch({ status: STATUS_PENDING })
    const response = await axiosInstance.post(`${apiEndpoint}/accounts/authenticate/by:credentials`, payload)
    localStorage.setItem(ACCESS_TOKEN_LS_KEY, response.data.access_token)
    dispatch({
      status: STATUS_RESOLVED,
      account: jwtDecode(response.data.access_token)
    })
    return <Navigate to='/home'></Navigate>
  } catch (error) {
    dispatch({
      status: STATUS_REJECTED,
      account: null,
      errors: error.response.data.errors
    })
  }
}

async function signout (dispatch) {
  dispatch({ status: STATUS_PENDING })
  await axiosInstance.delete(`${apiEndpoint}/accounts/tokens`)
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.removeItem(ACCESS_TOKEN_LS_KEY)
        dispatch(inialState)
        return <Navigate to='/signin'></Navigate>
      }
    })

  localStorage.removeItem(ACCESS_TOKEN_LS_KEY)
  dispatch(inialState)
  return <Navigate to='/signin'></Navigate>
}

AuthProvider.propTypes = {
  children: PropTypes.node
}

export { AuthProvider, useAuthState, useAuthDispatch, authenticate, signout, reset }
export default AuthStateContext
