import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

const checkIcon = <FontAwesomeIcon icon={faCheckCircle} color="green" />

function EmailInput (props) {
  return (
    <div className="field">
      <label className="label">{props.label }</label>
      <div className="control has-icons-right">
        <input
          className="input"
          type="email"
          placeholder={ props.placeholder }
          value={ props.value }
          onChange={ props.onChange }
          onBlur={ props.onBlur }
        />
        <span className="icon is-right">
          { props.isEmailValid && checkIcon }
        </span>
      </div>
      { !props.isEmailValid && props.touched && <p className="help is-danger">Please enter a valid email address</p> }
    </div>
  )
}

EmailInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isEmailValid: PropTypes.bool,
  touched: PropTypes.bool
}

EmailInput.defaultProps = {
  label: 'Email',
  placeholder: 'name@example.com'
}

export default EmailInput
