import './App.scss'
import { React } from 'react'
import { Routes, Route } from 'react-router-dom'
import Layout from './pages/Layout'
// import Signup from './pages/Signup'
import { AuthProvider } from './components/shared/AuthContext'
// import Friends from './pages/Friends'
// import ProtectedRoute from './components/shared/ProtectedRoute'
// import Home from './pages/Home'
// import Profile from './pages/Profile'
import TermsOfService from './pages/Terms'
import PrivacyPolicy from './pages/Privacy'
import Cookies from './pages/Cookies'
import Disclaimer from './pages/Disclaimer'
import NotFound from './pages/NotFound'
import Support from './pages/Support'
import Landing from './pages/Landing'

function App () {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route path='/' element={<Landing />} />
            <Route path='/terms-of-service' element={<TermsOfService />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/disclaimer' element={<Disclaimer />} />
            <Route path='/cookies' element={<Cookies />} />
            <Route path='/support' element={<Support />} />
            <Route path="*" element={<NotFound />} />
            {/* <Route path='/signup' element={<Signup />} />
            <Route path='/signin' element={<Signin />} />
            <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route path="/friends" element={<ProtectedRoute><Friends /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} /> */}
          </Route>
        </Routes>
      </AuthProvider>
    </>
  )
}

export default App
